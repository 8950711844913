html {
    font-size: 62.5%;
    width: 100%;
    height: 100%;

    background: var(--ui-color-background);
}

body {
    width: 100%;
    height: 100%;
    color: var(--ui-color-first);
}

p {
    line-height: 167%;
}

a {
    color: var(--ui-color-primary);
    text-decoration: underline;
    cursor: pointer;

    &:hover {
        color: var(--ui-color-primary-second);
    }
}

textarea {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    resize: none;
    background: var(--ui-color-surface);
    border: var(--ui-border);
    padding: 1rem;

    &:focus {
        outline: none;
    }
}

.clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: ' ';
    clear: both;
    height: 0;
}

/* start commented backslash hack \*/
* html .clearfix {
    height: 1%;
}

.clearfix {
    display: block;
}

.seperator {
    border-bottom: 1px solid var(--ui-color-border);
    text-align: center;
    height: 5px;
    margin: 25px 0;

    &__text {
        font-size: 1.4rem;
        display: inline-block;
        margin-top: -5px;
        padding: 0.1rem 2.2rem;
        background: var(--ui-color-surface-second);
        color: var(--ui-color-text);
    }
}

.bold {
    font-weight: 800;
}

.semibold {
    font-weight: 600;
}

hr {
    border-style: solid;
    border-bottom: 0;
    border-color: var(--ui-color-grey-93);
}

:focus {
    outline: none;
}

@-webkit-keyframes bfOverlay {
    0% {
        opacity: 0;
        transform: translateY(-15px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@-moz-keyframes bfOverlay {
    0% {
        opacity: 0;
        transform: translateY(-15px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@-ms-keyframes bfOverlay {
    0% {
        opacity: 0;
        transform: translateY(-15px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@-o-keyframes bfOverlay {
    0% {
        opacity: 0;
        transform: translateY(-15px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes bfOverlay {
    0% {
        opacity: 0;
        transform: translateY(-15px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@-webkit-keyframes bfOverlayClose {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes bfOverlayClose {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-ms-keyframes bfOverlayClose {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-o-keyframes bfOverlayClose {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes bfOverlayClose {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.bfOverlay {
    animation: bfOverlay 0.25s ease;
    transform: translateY(20px);
    animation-fill-mode: forwards;
    opacity: 0;

    &__close {
        opacity: 0;
        animation: bfOverlayClose 0.2s ease;
        animation-delay: 0.2s;
        animation-fill-mode: forwards;
    }
}

.pull-right {
    float: right;
}

.ui-svg-icon.help-icon {
    margin-left: 0.5rem;
    color: var(--default-color-grey);
    cursor: pointer;
    opacity: 0.5;

    &:hover {
        opacity: 1;
    }
}

.filters-applied {
    border: 1px solid var(--default-active-color);
}

.filters-applied::after {
    content: '';
    background-color: var(--default-active-color);
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    top: -0.4rem;
    right: -0.4rem;
}
