/* required styles for leaflet */
.leaflet-pane,
.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-tile-container,
.leaflet-pane > svg,
.leaflet-pane > canvas,
.leaflet-zoom-box,
.leaflet-image-layer,
.leaflet-layer {
    position: absolute;
    left: 0;
    top: 0;
}

.leaflet-container {
    overflow: hidden;
    background: var(--ui-color-grey-86);
    outline: 0;
    -webkit-tap-highlight-color: transparent;
    font:
        12px / 1.5 'Helvetica Neue',
        Arial,
        Helvetica,
        sans-serif;
}

.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow {
    -webkit-user-select: none; /* For Safari */
    -moz-user-select: none; /* For Firefox */
    -ms-user-select: none; /* For Edge */
    user-select: none; /* Non-prefixed version for modern browsers */
    -webkit-user-drag: none; /* Prevent dragging in WebKit browsers */
}

/* Safari renders non-retina tile on retina better with this, but Chrome is worse */
.leaflet-safari .leaflet-tile {
    image-rendering: -webkit-optimize-contrast;
}

/* hack that prevents hw layers "stretching" when loading new tiles */
.leaflet-safari .leaflet-tile-container {
    width: 1600px;
    height: 1600px;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
}

.leaflet-marker-icon,
.leaflet-marker-shadow {
    display: block;
}

.leaflet-container .leaflet-overlay-pane svg,
.leaflet-container .leaflet-marker-pane img,
.leaflet-container .leaflet-shadow-pane img,
.leaflet-container .leaflet-tile-pane img,
.leaflet-container img.leaflet-image-layer {
    max-width: none !important;
}

.leaflet-container.leaflet-touch-zoom {
    -ms-touch-action: pan-x pan-y;
    touch-action: pan-x pan-y;
}

.leaflet-container.leaflet-touch-drag {
    -ms-touch-action: pinch-zoom;
    touch-action: pinch-zoom;
}

.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
    -ms-touch-action: none;
    touch-action: none;
}

.leaflet-container {
    -webkit-tap-highlight-color: transparent;
}

.leaflet-container a {
    -webkit-tap-highlight-color: rgba(51, 181, 229, 0.4);
}

.leaflet-tile {
    filter: inherit;
    visibility: hidden;
}

.leaflet-tile-loaded {
    visibility: inherit;
}

.leaflet-zoom-box {
    width: 0;
    height: 0;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    z-index: 80;
    border: 2px dotted var(--ui-color-primary);
    background: rgba(255, 255, 255, 0.5);
}

.leaflet-overlay-pane svg {
    -moz-user-select: none;
}

.leaflet-pane {
    z-index: 40;
}

.leaflet-tile-pane {
    z-index: 20;
}

.leaflet-overlay-pane {
    z-index: 40;
}

.leaflet-shadow-pane {
    z-index: 50;
}

.leaflet-marker-pane {
    z-index: 60;
}

.leaflet-tooltip-pane {
    z-index: 65;
    color: var(--ui-color-text-third);
}

.leaflet-popup-pane {
    z-index: 70;
}

.leaflet-map-pane canvas {
    z-index: 10;
}

.leaflet-map-pane svg {
    z-index: 20;
}

.leaflet-vml-shape {
    width: 1px;
    height: 1px;
}

.lvml {
    behavior: url(#default#VML);
    display: inline-block;
    position: absolute;
}

/* control positioning */
.leaflet-control {
    position: relative;
    z-index: 80;
    pointer-events: visiblePainted; /* IE 9-10 doesn't have auto */
    pointer-events: auto;
    float: left;
    clear: both;
}

.leaflet-top,
.leaflet-bottom {
    position: absolute;
    z-index: 90;
    pointer-events: none;
}

.leaflet-top {
    top: 0;
}

.leaflet-right {
    right: 0;
}

.leaflet-bottom {
    bottom: 0;
}

.leaflet-left {
    left: 0;
}

.leaflet-control {
    float: left;
    clear: both;
}

.leaflet-right .leaflet-control {
    float: right;
    margin-right: 10px;
}

.leaflet-top .leaflet-control {
    margin-top: 10px;
}

.leaflet-bottom .leaflet-control {
    margin-bottom: 10px;
}

.leaflet-left .leaflet-control {
    margin-left: 10px;
}

/* zoom and fade animations */
.leaflet-fade-anim .leaflet-tile {
    will-change: opacity;
}

.leaflet-fade-anim .leaflet-popup {
    opacity: 0;
    -webkit-transition: opacity 0.2s linear;
    -moz-transition: opacity 0.2s linear;
    -o-transition: opacity 0.2s linear;
    transition: opacity 0.2s linear;
}

.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
    opacity: 1;
}

.leaflet-zoom-animated {
    -webkit-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    transform-origin: 0 0;
}



.leaflet-zoom-anim .leaflet-zoom-animated {
    -webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
    -moz-transition: -moz-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
    -o-transition: -o-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
    transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1);
    will-change: transform;
}

.leaflet-zoom-anim .leaflet-tile,
.leaflet-pan-anim .leaflet-tile {
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    transition: none;
}

.leaflet-zoom-anim .leaflet-zoom-hide {
    visibility: hidden;
}

.leaflet-interactive {
    cursor: pointer;
}

.leaflet-grab {
    cursor: -webkit-grab;
    cursor: -moz-grab;
}

.leaflet-crosshair,
.leaflet-crosshair .leaflet-interactive {
    cursor: crosshair;
}

.leaflet-popup-pane,
.leaflet-control {
    cursor: auto;
    font-size: 10px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.leaflet-dragging .leaflet-grab,
.leaflet-dragging .leaflet-grab .leaflet-interactive,
.leaflet-dragging .leaflet-marker-draggable {
    cursor: move;
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
}

/* marker & overlays interactivity */
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-image-layer,
.leaflet-pane > svg path,
.leaflet-tile-container {
    pointer-events: none;
}

.leaflet-marker-icon.leaflet-interactive,
.leaflet-image-layer.leaflet-interactive,
.leaflet-pane > svg path.leaflet-interactive {
    pointer-events: visiblePainted; /* IE 9-10 doesn't have auto */
    pointer-events: auto;
}

.leaflet-container {
    background: var(--ui-color-grey-86);
    outline: 0;
    font:
        12px / 1.5 'Helvetica Neue',
        Arial,
        Helvetica,
        sans-serif;
}

.leaflet-container a {
    color: #0078a8;
}

.leaflet-container a.leaflet-active {
    outline: 2px solid orange;
}

.leaflet-zoom-box {
    border: 2px dotted var(--ui-color-primary);
    background: rgba(255, 255, 255, 0.5);
}


/* general toolbar styles */
.leaflet-bar {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
    border-radius: 4px;
}

.leaflet-bar a,
.leaflet-bar a:hover {
    background-color: var(--ui-color-surface);
    border-bottom: 1px solid var(--ui-color-grey-84);
    width: 23px;
    height: 23px;
    line-height: 23px;
    display: block;
    text-align: center;
    text-decoration: none;
    color: var(--ui-color-grey-21);
}

.leaflet-bar a,
.leaflet-control-layers-toggle {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    display: block;
}

.leaflet-bar a:hover {
    background-color: #f4f4f4;
}

.leaflet-bar a:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.leaflet-bar a:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom: none;
}

.leaflet-bar a.leaflet-disabled {
    cursor: default;
    background-color: #f4f4f4;
    color: var(--ui-color-text-second);
}

.leaflet-touch .leaflet-bar a:first-child {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
}

.leaflet-touch .leaflet-bar a:last-child {
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
}

/* zoom control */
.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
    font:
        bold 18px 'Lucida Console',
        Monaco,
        monospace;
    text-indent: 1px;
}

/* layers control */
.leaflet-control-layers-overlays {
    margin: 5px 10px;
}

.leaflet-control-layers-overlays > label > div > span {
    cursor: pointer;
}

.leaflet-control-layers {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
    background: var(--ui-color-surface);
    border-radius: 5px;
}

.leaflet-control-layers-toggle {
    font: normal normal normal 24px 'BannerflowMaterial', sans-serif;
    height: 34px;
    margin-left: auto;
    margin-right: auto;
    width: auto;
    padding: 4px 5px 5px 5px;
}

.leaflet-control-layers-toggle:before {
    content: '\f0ac';
    color: #494949;
}

a.leaflet-control-layers-toggle {
    color: rgba(255, 255, 255, 0);
}

.leaflet-retina .leaflet-control-layers-toggle {
    font: normal normal normal 24px 'BannerflowMaterial', sans-serif;
    height: 34px;
    margin-left: auto;
    margin-right: auto;
    width: auto;
    padding: 4px 5px 5px 5px;
}

.leaflet-touch .leaflet-control-layers-toggle {
    width: 34px;
    height: 34px;
}

.leaflet-control-layers .leaflet-control-layers-list,
.leaflet-control-layers-expanded .leaflet-control-layers-toggle {
    display: none;
}

.leaflet-control-layers-expanded .leaflet-control-layers-list {
    display: block;
    position: relative;
}

.leaflet-control-layers-expanded {
    padding: 6px 10px 6px 6px;
    color: var(--ui-color-first);
    background: var(--ui-color-surface);
}

.leaflet-control-layers-scrollbar {
    overflow-y: scroll;
    overflow-x: hidden;
    padding-right: 5px;
}

.leaflet-control-layers-selector {
    position: relative;
    margin: 1px 3px 5px 0;
    cursor: pointer;
    vertical-align: middle;
    display: inline-block;
    box-sizing: border-box;
    width: 1.3rem;
    height: 1.3rem;
    padding: 0.2rem 0;
    font: normal normal normal 13px 'BannerflowMaterial', sans-serif;
}

.leaflet-control-layers-selector:before {
    content: '\f03e';
    color: var(--ui-color-surface);
    line-height: 1.4rem;
    text-align: center;
    position: absolute;
    pointer-events: none;
    left: 0;
    top: 0.8rem;
    z-index: 1;
    opacity: 0;
    border: 0.1rem solid transparent;
    transition: all 0.2s ease;
}

.leaflet-control-layers-selector:checked:before {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    top: 0;
    opacity: 1;
}

.leaflet-control-layers-selector:checked:after {
    background: var(--ui-color-primary);
    border-color: var(--ui-color-primary);
    box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0);
}

.leaflet-control-layers-selector:after {
    box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.2);
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 1.3rem;
    height: 1.3rem;
    background: var(--ui-color-surface);
    cursor: pointer;
    border-radius: 0.3rem;
    border: 0.1rem solid var(--ui-color-grey-61);
    transition: all 0.2s ease;
}

.leaflet-control-layers label {
    display: block;
}

.leaflet-control-layers-separator {
    height: 0;
    border-top: 1px solid var(--ui-color-grey-86);
    margin: 5px -10px 5px -6px;
}

/* attribution and scale controls */
.leaflet-container .leaflet-control-attribution {
    background: var(--ui-color-surface);
    background: rgba(255, 255, 255, 0.7);
    margin: 0;
    opacity: 0.2;
    transition: opacity 0.2s ease-in-out;
}

.leaflet-container .leaflet-control-attribution:hover {
    opacity: 1;
}

.leaflet-control-attribution,
.leaflet-control-scale-line {
    padding: 0 5px;
    color: var(--ui-color-first);
}

.leaflet-control-attribution a {
    text-decoration: none;
}

.leaflet-control-attribution a:hover {
    text-decoration: underline;
    opacity: 1;
}

.leaflet-container .leaflet-control-attribution,
.leaflet-container .leaflet-control-scale {
    font-size: 11px;
}

.leaflet-left .leaflet-control-scale {
    margin-left: 5px;
}

.leaflet-bottom .leaflet-control-scale {
    margin-bottom: 5px;
}

.leaflet-control-scale-line {
    border: 2px solid var(--ui-color-grey-61);
    border-top: none;
    line-height: 1.1;
    padding: 2px 5px 1px;
    font-size: 11px;
    white-space: nowrap;
    overflow: hidden;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    background: var(--ui-color-surface);
    background: rgba(255, 255, 255, 0.5);
}

.leaflet-control-scale-line:not(:first-child) {
    border-top: 2px solid var(--ui-color-grey-61);
    border-bottom: none;
    margin-top: -2px;
}

.leaflet-control-scale-line:not(:first-child):not(:last-child) {
    border-bottom: 2px solid var(--ui-color-grey-61);
}

.leaflet-touch .leaflet-control-attribution,
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
    box-shadow: none;
}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
    box-shadow: 0 1px 3px var(--ui-color-grey-61);
    background-clip: padding-box;
    opacity: 0.9;
    border-radius: 2px;
}

/* popup */
.leaflet-popup {
    position: absolute;
    text-align: center;
    margin-bottom: 20px;
}

.leaflet-popup-content-wrapper {
    padding: 1px;
    text-align: left;
    border-radius: 12px;
}

.leaflet-popup-content {
    margin: 13px 19px;
    line-height: 1.4;
}

.leaflet-popup-content p {
    margin: 18px 0;
}

.leaflet-popup-tip-container {
    width: 40px;
    height: 20px;
    position: absolute;
    left: 50%;
    margin-left: -20px;
    overflow: hidden;
    pointer-events: none;
}

.leaflet-popup-tip {
    width: 17px;
    height: 17px;
    padding: 1px;

    margin: -10px auto 0;

    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
    background: white;
    color: var(--ui-color-first);
    box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
}

.leaflet-container a.leaflet-popup-close-button {
    position: absolute;
    top: 0;
    right: 0;
    padding: 4px 4px 0 0;
    border: none;
    text-align: center;
    width: 18px;
    height: 14px;
    font:
        16px / 14px Tahoma,
        Verdana,
        sans-serif;
    color: #c3c3c3;
    text-decoration: none;
    font-weight: bold;
    background: transparent;
}

.leaflet-container a.leaflet-popup-close-button:hover {
    color: var(--ui-color-grey-61);
}

.leaflet-popup-scrolled {
    overflow: auto;
    border-bottom: 1px solid var(--ui-color-grey-86);
    border-top: 1px solid var(--ui-color-grey-86);
}

.leaflet-oldie .leaflet-popup-content-wrapper {
    zoom: 1;
}

.leaflet-oldie .leaflet-popup-tip {
    width: 24px;
    margin: 0 auto;

    filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678);
    -ms-filter: 'progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678)';
}

.leaflet-oldie .leaflet-popup-tip-container {
    margin-top: -1px;
}

.leaflet-oldie .leaflet-control-zoom,
.leaflet-oldie .leaflet-control-layers,
.leaflet-oldie .leaflet-popup-content-wrapper,
.leaflet-oldie .leaflet-popup-tip {
    border: 1px solid var(--ui-color-grey-61);
}

.leaflet-div-icon {
    background: var(--ui-color-surface);
    border: 1px solid var(--ui-color-grey-61);
}

/* Tooltip */
.leaflet-tooltip {
    position: absolute;
    padding: 6px;
    background-color: var(--ui-color-grey-21);
    border: 1px solid var(--ui-color-grey-21);
    border-radius: 0;
    color: var(--ui-color-surface) fff;
    opacity: 0.9;
    white-space: nowrap;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
}

.leaflet-tooltip.leaflet-clickable {
    cursor: pointer;
    pointer-events: auto;
}

.leaflet-tooltip-top:before,
.leaflet-tooltip-bottom:before,
.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
    position: absolute;
    pointer-events: none;
    border: 6px solid transparent;
    background: transparent;
    content: '';
}

/* Directions */
.leaflet-tooltip-bottom {
    margin-top: 6px;
}

.leaflet-tooltip-top {
    margin-top: -6px;
}

.leaflet-tooltip-bottom:before,
.leaflet-tooltip-top:before {
    left: 50%;
    margin-left: -6px;
}

.leaflet-tooltip-top:before {
    bottom: 0;
    margin-bottom: -12px;
    border-top-color: var(--ui-color-grey-21);
}

.leaflet-tooltip-bottom:before {
    top: 0;
    margin-top: -12px;
    margin-left: -6px;
    border-bottom-color: var(--ui-color-grey-21);
}

.leaflet-tooltip-left {
    margin-left: -6px;
}

.leaflet-tooltip-right {
    margin-left: 6px;
}

.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
    top: 50%;
    margin-top: -6px;
}

.leaflet-tooltip-left:before {
    right: 0;
    margin-right: -12px;
    border-left-color: var(--ui-color-grey-21);
}

.leaflet-tooltip-right:before {
    left: 0;
    margin-left: -12px;
    border-right-color: var(--ui-color-grey-21);
}